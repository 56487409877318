import React from "react"
import Layout from "../components/layout"
import Header from "../components/UI/Header"
import { SecondaryHeader } from "../components/UI/TextHeaders"
import Button from "../components/UI/Buttons"

const Error = props => {
  console.log(props)
  return (
    <Layout status={props.transitionStatus}>
      <Header>
        <div>
          <SecondaryHeader>
            404 - Deze pagina bestaat helaas niet. Keer terug naar de
            hoofdpagina
          </SecondaryHeader>
          <Button arrow to="/">
            Terug naar hoofdpagina
          </Button>
        </div>
      </Header>
    </Layout>
  )
}

export default Error
